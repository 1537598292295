<template>
    <div class="container">
        <div class="container__wrap">
            <div class="section">
                <div class="section__content2">
                    <div class="content__form">
                        <div class="form__item">
                            <label class="item__name" for="inptName">Логин:</label>
                            <input required class="item__input" v-model="this.username" type="text" placeholder="Введите логин" id="inptName">
                        </div>
                        <div class="form__item">
                            <label class="item__name" for="inptPass">Пароль:</label>
                            <input required class="item__input"  v-model="this.password" type="password" placeholder="Введите пароль" id="inptPass">
                        </div>
                        <div class="form__item">
                            <label class="item__name" for="inptSecret">Секретный ключ:</label>
                            <input required class="item__input"  v-model="this.secret" type="text" placeholder="Введите секретный ключ" id="inptSecret">
                        </div>
                        <div class="form__item">
                            <button type="submit" class="btn" v-on:click="registration()">Войти</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import action from '../services/main.js'

export default {
    name: "AdminRegistration",
    data() {
        return {
            username: '',
            password: '',
            secret: ''
        }
    },
    methods: {
        registration() {
            if(this.username && this.password && this.secret) {
                action.adminRegistration(this.username, this.password, this.secret).then(res => {
                    if(res.data['code'] == 200) {
                        this.$notify({
                            title: "Успех",
                            text: res.data['message'],
                            duration: 2000,
                            type: 'success',
                        }); 
                        this.username = '',
                        this.password = ''
                    } else {
                        this.$notify({
                            title: "Внимание",
                            text: res.data['message'],
                            duration: 2000,
                            type: 'warn',
                        }); 
                    }
                });
            }else {
                this.$notify({
                    title: "Внимание",
                    text: 'Заполните данные',
                    duration: 2000,
                    type: 'warn',
                }); 
            }
        }
    }
}
</script>

<style scoped>
.section {
    margin: 50px 0;
}
.section__content2 {
    max-width: 500px;
    margin: 0 auto;
}
.content__form {
    width: 100%;
}
.form__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.item__name {
    font-weight: 400;
    font-size: 16px;
}
.item__input {
    background: #F8F8F8;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
}
.btn {
    padding: 10px 0;
    background: linear-gradient(95.41deg, #FFD8F6 0%, #FFD8E1 100%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.btn:hover {
    cursor: pointer;
}
</style>